/*
 * About us Page
 */


.page-services {
  #top-section {
    @include top-section('/images/frontend/images/page-services.jpg');
    height: 36rem;

    .container-fluid {

      .row-custom {
        @media (max-width: 767px) {
          margin: 0;
        }

        .col-custom {
          @media (max-width: 767px) {
            padding: 0;
          }
          
          .bg-white {
            @media (max-width: 767px) {
              max-width: 100%
            } 
          }
        }
      }

      .box {
        padding: 0;

        .box-header {
          padding: 3.75rem 8.8125rem 2.4375rem 4.0625rem;
          @media (max-width: 695px) {
            padding: 2rem 1rem;
          }

          p {
            margin-top: 1.875rem;
          }
        }

        .box-body {
          .nav-tabs {
            .nav-item {
              .nav-link {
                position: relative;
                height: 100%;
                padding: 1em 1em 1em 0;
                border: none;
                text-align: left;
                font-size: 1.375rem;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $body-color;
                background: #ffffff;
                overflow: hidden;
                transition: background-color .2s, color .2s;

                @media (max-width: 767px) {
                  padding-left: 1em!important;
                  font-size: 1rem;

                  &:after {
                    left: 1em!important;
                  };
                }

                &:first-child {
                  padding-left: 4.0625rem;

                  &:after {
                    left: 4.0625rem;;
                  }
                }

                &.bottom-outline {
                  &:after {
                    background: $black;
                    width: 3.7rem;
                    transition: width .2s, background-color .2s;
                  }
                }

                &.active, &:hover {
                  background: #f7f7f7 !important;
                }
              }
            }
          }
        }
      }
    }

    &:after {
      bottom: -1px;
    }
  }

  #service-providers-section {
    padding: 6.875rem 0;

    .container-fluid {
      max-width: 1320px;


      .left-box {
        width: 100%;
        max-width: 680px;
        flex-basis: auto;

        h2 {
          padding-left: 4.0625rem;
        }

        .spd-box {
          margin-top: 3rem;
          padding-bottom: 4rem;

          .spd-filter {
            flex-wrap: wrap;
            padding-top: 1rem;
            padding-left: 4.0625rem;
            padding-right: 2.5rem;

            .custom-select-field {
              width: 16.25rem;

              label {
                font-size: 0.875rem;
                color: #999999;
              }

              .select-wrapper {
                position: relative;

                &:before {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  width: 1.875rem;
                  height: 2px;
                  background-color: $accent-color;
                }

                &:after {
                  content: "";
                  background: {
                    size: cover;
                    position: center center;
                    image: url("../../../../public/images/frontend/chevron-down.png");
                  }
                  opacity: 0.3;
                  display: inline-block;
                  position: absolute;
                  right: 0;
                  top: 35%;
                  width: 14px;
                  height: 14px;
                }
              }

              select {
                color: #000000;
                padding-left: 3.125rem;
                padding-right: 1rem;
                line-height: 1.33;
              }
            }

            .results {
              margin-top: 0.85rem;
              line-height: 1.33;
              color: #666666;
            }

            @media (max-width: 599px) {
              padding-right: 15px;
              padding-left: 15px;
            }
          }

          .spd-table {
            margin-top: .75rem;
            color: #999999;
            line-height: normal;
            max-height: 29rem;
            overflow-y: auto;
            position: relative;

            .ps__rail-y {
              background: transparent !important;

              &:hover, &:focus, &.ps--clicking {
                .ps__thumb-y {
                  width: 6px;
                }
              }

              .ps__thumb-y {
                left: -2px;
              }
            }


            .tab-row-wrapper {
              position: relative;
              padding-left: 4.0625rem;
              padding-right: 2.5rem;

              &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 2px;
                top: 48px;
                left: 0;
                background-color: #fe0000;
                transition: width .2s;
              }

              &.active {
                background-color: #f7f7f7;
                &:before {
                  width: 1.875rem;
                }
              }

              .tab-row {
                flex-wrap: wrap;
                border-bottom: solid 1px rgba(0, 0, 0, 0.2);
                padding: 1.25rem 0;

                &.pt-0 {
                  padding-top: 1.25rem!important;
                }

                .tab-cel {
                  max-width: 200px;

                  @media (max-width: 599px) {
                    margin-bottom: 15px;
                  }

                  &:not(.collapse) {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                  }

                  .text-black {
                    display: inline-block;
                    vertical-align: middle;
                    color: #000000;
                  }

                  .btn {

                    &:not(.collapsed){
                      color: #ffffff;
                      background-color: $accent-color;
                      border-color: $accent-color;
                    }

                  }
                }

                .collapse, .collapsing {

                  a {
                    font-size: 0.875rem;
                    margin-right: 1.875rem;
                    white-space: nowrap;

                    &:hover {
                      color: $accent-color !important;
                    }
                  }
                }

                @media (max-width: 599px) {
                  padding-right: 15px;
                  padding-left: 15px;
                  flex-direction: column;
                }

              }

              @media (max-width: 599px) {
                padding-right: 15px;
                padding-left: 15px;
              }
            }

            .serv-menu {
              width: 100%;
            }
          }
        }

        @media (max-width: 1319px) {
          max-width: 100%;
        }

      }

      .right-box {
        width: 100%;
        max-width: 640px;
        padding-bottom: 4rem;

        @media (max-width: 1319px) {
          max-width: 100%;
          height: 450px;
        }

        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          left: -12px;
          top: 6%;
          width: 2rem;
          height: 2px;
          background-color: $accent-color;
          z-index: 2;
        }

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 25%;
          height: 3.18rem;
          background-color: #f7f7f7;
        }

        .map-box {
          height: 100%;
          max-height: 49.5rem;
          border: solid 1px rgba(0, 0, 0, 0.2);

          .gm-control-active {
            &:hover {
              color: #ffffff;
              background-color: $accent-color;
            }
          }
        }
      }
    }
  }

  #service-providers-section {
    .map-box{
      z-index: 1000 !important;
    }

    .dropdown-with-checkboxes {
      margin-top:-14px;
      .dropdown-filter-secondary {
        border-bottom: 1px solid #999999;
        .dropdown {
          a {
            color: #000000;
            padding-left: 3.125rem;
            padding-right: 1rem;
            line-height: 1.33;
            display: block;
            width: 100%;
            &:before {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              top: 50%;
              width: 1.875rem;
              height: 2px;
              background-color: #fe0000;
            }
            &:after {
              content: "";
              background: {
                size: cover;
                position: center center;
                image: url("../../../../public/images/frontend/chevron-down.png");
              }
              opacity: 0.3;
              display: inline-block;
              position: absolute;
              right: 0;
              top: 20%;
              width: 14px;
              height: 14px;
              border: none;
            }
            &:hover {
              text-decoration: none;
            }
          }

          .filter-options {
            width: 100%;
            max-width: 100%;
            max-height: 300px;
            overflow: hidden;
            overflow-y: scroll;
            .item-checkbox {
              min-height: 2rem;
              padding: 0 15px;
              white-space: normal;
              .custom-checkbox {
                width: 100%;
                label {
                  width: 100%;
                  line-height: 30px;
                  &:hover {
                    cursor:pointer;
                  }
                  &:before {
                    top: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .selected-filter-options {
      span {
        background-color: red;
        border: 1px solid red;
        border-radius: 15px;
        margin-left: 10px;
        margin-bottom: 5px;
        color: #ffffff;
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;
        i {
          font-size: 20px;
          font-style: normal;
          width: 16px;
          display: inline-block;
          text-align: center;
          height: 30px;
          line-height: 24px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  #coalition-agencies {
    padding: 6.875rem 0;

    .container-fluid {
      max-width: 1190px;

      .dropdown-with-checkboxes {
        .dropdown-filter-secondary {
          border-bottom: 1px solid #999999;
          .dropdown {
            a {
              color: #000000;
              padding-left: 3.125rem;
              padding-right: 1rem;
              line-height: 1.33;
              display: block;
              width: 100%;
              &:before {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 50%;
                width: 1.875rem;
                height: 2px;
                background-color: #fe0000;
              }
              &:after {
                content: "";
                background: {
                  size: cover;
                  position: center center;
                  image: url("../../../../public/images/frontend/chevron-down.png");
                }
                opacity: 0.3;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 20%;
                width: 14px;
                height: 14px;
                border: none;
              }
              &:hover {
                text-decoration: none;
              }
            }
            .filter-options {
              width: 100%;
              max-width: 100%;
              max-height: 300px;
              overflow: hidden;
              overflow-y: scroll;
              .item-checkbox {
                min-height: 2rem;
                padding: 0 15px;
                white-space: normal;
                .custom-checkbox {
                  width: 100%;
                  label {
                    width: 100%;
                    line-height: 30px;
                    &:hover {
                      cursor:pointer;
                    }
                    &:before {
                      top: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .selected-filter-options {
        span {
          background-color: red;
          border: 1px solid red;
          border-radius: 15px;
          margin-left: 10px;
          margin-bottom: 5px;
          color: #ffffff;
          display: inline-block;
          padding: 0 10px;
          font-size: 14px;
          i {
            font-size: 20px;
            font-style: normal;
            width: 16px;
            display: inline-block;
            text-align: center;
            height: 30px;
            line-height: 24px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .filter-box {
        .col {
          &:first-child {
            max-width: 18rem;

            @media (max-width: 959px) {
              width: 100%;
              max-width: 100%;
              flex-basis: auto;
            }
          }

          &:last-child {
            @media (max-width: 767px) {
              flex-wrap: wrap;
              justify-content: start!important;
            }

            h5 {
              @media (max-width: 959px) {
                width: 100%;
              }
            }
          }

          .h5 {
            font-size: 1.375rem;
            height: 100%;

            @media (max-width: 767px) {
              width: 100%;
            }
          }

          .custom-select-field {
            width: 13.75rem;

            @media (max-width: 959px) {
              margin-right: 15px;

              &:last-child {
                margin-right: 0;
              }
            }

            @media (max-width: 550px) {
              width: 100%;
              margin-right: 0;
            }

            label {
              font-size: 0.875rem;
              color: #999999;
            }

            .select-wrapper {
              position: relative;

              &:before {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 50%;
                width: 1.875rem;
                height: 2px;
                background-color: $accent-color;
              }

              &:after {
                content: "";
                background: {
                  size: cover;
                  position: center center;
                  image: url("../../../../public/images/frontend/chevron-down.png");
                }
                opacity: 0.3;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 35%;
                width: 14px;
                height: 14px;
              }
            }

            select {
              color: #000000;
              padding-left: 3.125rem;
              padding-right: 1rem;
              line-height: 1.33;
            }
          }
        }
      }

      .agencies-cards {

        .col {
          padding: 1.25rem;

          .card {
            position: relative;
            padding: 1.25rem;
            box-shadow: none;
            border-top: 2px solid transparent;
            position: relative;
            overflow: hidden;
            transition: border-color .2s;

            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 2px;
              top: 0;
              left: 50%;
              background-color: $accent-color;
              transform: translateX(-50%);
              transition: width .2s;
            }

            &:hover {
              // border-color: $accent-color;
              &:before {
                width: 100%;
              }
            }

            .service-categories {
              background-color: red;
              position: absolute;
              right: -55%;
              top: 0;
              color: #ffffff;
              width: 55%;
              height: 100%;
              padding: 30px 15px 15px;
              z-index: 1;
              opacity: 0;
              visibility: hidden;
              -webkit-transition: right .3s, opacity .3s, visibility .3s;
              -moz-transition: right .3s, opacity .3s, visibility .3s;
              -ms-transition: right .3s, opacity .3s, visibility .3s;
              -o-transition: right .3s, opacity .3s, visibility .3s;
              transition: right .3s, opacity .3s, visibility .3s;
              &.active {
                right: 0;
                opacity: 1;
                visibility: visible;
              }
              span {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 10px;
                font-size: 20px;
                &:hover {
                  cursor: pointer;
                }
              }
              ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                  padding-bottom: 15px;
                  line-height: 16px;
                  &:last-child {
                    padding-bottom: 0;
                  }
                }
              }
            }

            .click-service-categories {
              -webkit-transition: background-color .3s, border-color .3s, color .3s;
              -moz-transition: background-color .3s, border-color .3s, color .3s;
              -ms-transition: background-color .3s, border-color .3s, color .3s;
              -o-transition: background-color .3s, border-color .3s, color .3s;
              transition: background-color .3s, border-color .3s, color .3s;
              &:hover {
                cursor: pointer;
                background: red
              }
              &.clicked {
                background-color: red !important;
                color: #ffffff !important;
                border-color: red !important;
              }
            }

            .card-body {
              padding: 0;
              a {
                text-decoration: none;

              }
                h3 {
                  margin-top: 15px;
                  margin-bottom: 0;
                  font-size: 1.125rem;
                  font-weight: 500;
                  line-height: 1.33;
                  color: #000;
                }

              p {
                margin-top: 0.625rem;
                margin-bottom: 0;
                opacity: 0.6;
                font-size: 0.875rem;
                color: #000;
              }

              .btn-bordered {
                margin-top: 0.75rem;
                display: inline-block;
                &:hover {
                  color: #999999;
                  background-color: #ffffff;
                  border-color: #999999;
                }
              }

            }

            .card-footer {
              border-top: none;
              padding: 0;
              display: flex;
              justify-content: space-between;

              .btn {
                font-weight: normal;
                margin-top: 1.4375rem;
                margin-bottom: 0;
                padding: 0 0 1rem 0;
                position: relative;
                font-size: 14px;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #999999;
                transition: color .2s;

                &.active:after, &:hover:after {
                  width: 2.5rem !important;
                }

                &:after {
                  background: rgba($black, .9);
                  width: 2.5rem;
                  transition: background-color .2s, opacity .2s;
                }
              }
            }
          }
        }
      }
    }
  }

  #create-account {
    background: #ffffff;


  }
}
