/**
Agency inside
 */

.page-agency-inside {
  .fas {
    width: 25px;
  }
  main {
    height: 100%!important;
  }
  #top-section {
    display: flex;
    justify-content: center;

    .container-fluid {
      display: flex;
      justify-content: center;
      .box {
        @media (max-width: 992px) {
          padding: 2rem;
        }
        @media (max-width: 768px) {
          padding-bottom: 0;
        }
        padding: 2.25rem 4.0625rem 0 4.0625rem;

        .h1 {
          margin-bottom: 0;
          text-align: center;
        }
        figure {
          display: flex;
          justify-content: center;
        }
      }
    }

  }

  i {
    color: #ff0000;
    font-size: 18px;
    font-weight: 900;
    margin-right: 10px !important;
  }

  .margin-left-25{
    margin-left: 25px;
  }

  .list-group-item {
    padding-left: 0;
  }

  aside {
    padding: 25px 0 0 0;

    .list-group-item {
      flex-wrap: inherit;
      align-items: flex-start;
      line-height: 1.4;
      padding: 0 0 5px 0;
    }

    .list-group-item :first-child {
      margin-right: 0;
    }

  }

  .languages, .agency-services, address {
    li {
      font-size: 14px;
      color: #000000;
      font-weight: 400;
      margin-top: 5px;
      padding: 0 0 5px 0;
    }
  }

  .btn-appointment {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    padding: 20px 40px;
    border-radius: 5px;
    border-width: 3px;
  }

  main {
    padding-top: 20px;
    height: 1px;
  }

  @media (max-width: 768px) {
    .mobile-padding-left-40 {
      padding-left: 40px;
    }
    .mobile-padding-bottom-40 {
      padding-bottom: 40px;
    }

  }

}
