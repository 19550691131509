@mixin top-section($backgroundImageUrl, $max-width:41.625rem) {
    height: 45rem;
    position: relative;
    background: {
      size: cover;
      position: center center;
      image: url($backgroundImageUrl);
    }


    &:after {
      content: "";
      width: 20%;
      height: 6.875rem;
      background-color: #f7f7f7;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }


    .container-fluid {
      @media (min-width: 992px) {
        max-width: 960px;
      }

      @media (min-width: 1200px) {
        max-width: 1320px;
      }

      .box {
        @media (max-width: 992px) {
          padding: 2rem;
        }
        max-width: $max-width;
        padding: 3.75rem 4.0625rem 2.875rem 4.0625rem;
        z-index: 2;
        position: relative;

        .return-back {
          font-size: 1.125rem;
          line-height: 1.33;
          color: #666666;
          text-decoration: none;
        }

        figure{
          margin-top: 0.4rem;
        }

        .h5 {
          margin-top: 1.875rem;
          font-size: 1.5rem;
          line-height: 1.25;
        }
      }
    }
}

