// Variables
@import "_variables";
// Bootstrap
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~bootstrap-material-design/scss/bootstrap-material-design";
// Plugins
@import "~sweetalert2/src/sweetalert2";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~intl-tel-input/src/css/intlTelInput";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~noty/src/noty";
@import "~noty/src/themes/bootstrap-v4";

//Utils
@import "utils/_utils-dir";
// Components
@import "../buttons";
@import "../vendor/tabler/scss/dashboard/fonts/feather";
// Sections
@import "header";
@import "footer";
// Pages
@import "pages/_pages-dir";
