.browser-message {
  display:none;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .browser-message {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    border-bottom: 1px solid #888;
    display: block;
  }
}

.container {
  @media (min-width: 1200px) {
    max-width: 1190px;
  }
  @media (max-width: 991.98px) {
    max-width: 100%;
  }
}

.text-opacity {
  opacity: 0.6;
}

.lang-dropdown {
  text-transform: capitalize;
}

h4 {
  font-size: 18px;
  color: #000000;

}

.btn-outline-primary:focus {
  background-color: transparent !important;
}

/*
* === Main Banner ===
*/
#top-section {
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
  }
}

/*
* === available-agencies ===
*/
#available-agencies {
  p {
    font-size: 1.375rem;
    line-height: normal
  }

  .btn {
    margin: 0.5rem 0;
    width: 170px;
    font-weight: normal;
  }
}

/*
* === Create account ===
*/
#create-account {
  p {
    font-size: 1.375rem;
  }

  //.btn {
  //  padding: 0.38rem 0 1rem 0;
  //  width: 170px;
  //  margin: 2.5rem 0;
  //  font-weight: normal;
  //
    //@media (max-width: 599px) {
    //  margin-top: 1.5rem;
    //  margin-bottom: 1.5rem;
    //}
  //}
}

/*
* === Sign in/up modal ===
*/

#sign-in-modal, #sign-up-modal, #sign-in-connect-modal, #create-account-modal, #create-account-connect-modal, #forget-password-modal, #browser-modal {
    .modal-dialog {
      max-width: 52.5rem;
      align-items: center;
      justify-content: center;
    }

    .close-modal-link {
      position: relative;
      right: 5px;
      top: 10px;
      float: right;
      cursor: pointer;
    }

    .modal-message {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .display-flex {
      display: flex;
    }

    .p-2_5 {
      padding: 2.5rem;
    }

    .modal-left-box {
      border: 2px solid #000000;
      margin: 15px 0;
      flex-wrap: wrap;
      padding: 75px 0;
      height: 95%;

      .modal-image {
        width: 100px;
        padding-bottom: 20px;
      }

      h4 {
        font-size: 24px;
        font-weight: 300;
        font-color: #000000;
        padding-bottom: 20px;
      }

      ul {
        margin: 0 25%;
        li {
          margin: 10px 0;
          color: #000000;
        }
      }
  }

  .modal-right-box {
    padding: 0 15px;
    .nav {
      border-bottom: 1px solid #ccc;

      .nav-item {
        .nav-link {
          border: none;
          padding: 0 0 1.43rem 0;
          position: relative;
          font-size: 1.375rem;
          font-weight: normal;

          @media (max-width: 414px) {
            height: 100%;
            font-size: 1rem;
          }

          &.active:after, &:hover:after {
            width: 100% !important;
          }
        }
      }
    }

    h3 {
      color: #000000;
      font-size: 32px;
      font-weight: 300;
      margin: 25px;
    }

    .form-control {
      border: 1px solid #818a91;
      border-radius: 3px;
      font-size: 15px;
      margin-bottom: 10px;
      color: #333333;
      padding: 15px;
      background-image: none;
      margin-top: 8px;
    }

    .form-group {
      margin-bottom: 0;
    }

    .forgot-pass-link {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      text-decoration: underline;
    }

    .btn-outline-primary {
      color: #000000;
      font-size: 17px;
      font-weight: 700;
      border: 2px solid #FF0000;
      border-radius: 3px;
      text-transform: none;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 500px;
      white-space: normal;
    }

    .sign-in-link {
      font-size: 16px;
      font-weight: 700;
      color: #000000;
      text-decoration: underline;
    }


    label {
      // text-transform: capitalize;
      font-size: 13px;
      font-weight: 500;
      color: #333333;
    }

    .modal-body {
      p {
        font-size: 0.875rem;
      }
      .forgot-pass-link {
        font-size: 0.875rem;
        color: $body-color;
      }

      #sign-up-form {
        .form-group {
          padding-top: 1rem;
        }
      }
    }

    .modal-footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      .btn {
        padding: 0 0 1rem 0;
        position: relative;
        min-width: 50%;
        font-weight: normal;

        &.active:after, &:hover:after {
          width: 100% !important;
        }

        &:after {
          background: #000000;
          width: 2rem;
        }
      }

      @media (max-width: 768px) {
        position: relative;
      }
    }
  }
}

#sign-in-modal, #sign-up-modal, #forget-password-modal {
  .modal-dialog {
    max-width: 450px;
    align-items: center;
    justify-content: center;
  }
}

#sign-in-modal, #sign-up-modal {
  form {
    margin-bottom: 0;
  }

  .modal-right-box {
    padding-bottom: 20px;
  }
}

/**
Post section
 */
.post-section{
  padding-bottom: 3.75rem;
  .section-header{
    p{
      //opacity: 0.6;
      margin-top: 1.875rem;
    }
  }
  .section-body{
    margin-top: 3.75rem;

    .section-body-header{
      .dropdown {
        .btn {
          font-size: 0.875rem;
          color: #666666;
        }
      }
      p{
        opacity: 0.6;
        margin-top: 1.875rem;
      }
    }
    article {
      margin-top: 2.5rem;
      .h4 {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.33;
      }
      p{
        opacity: 0.6;
        font-size: 0.875rem;
        margin: 1.25rem 0;
      }
      .bottom-outline {
        &.active:after, &:hover:after {
          width: 2.5rem!important;
        }
      }
    }
  }
}
.post-asides {
  aside {
    padding: 2.5rem;
    background-color: #ffffff;
    margin-bottom: 2.5rem;
    .aside-header{
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.33;
    }

    .aside-body{
      .custom-bullet {
        .list-group-item{
          position: relative;
          padding-left: 34px;
          &:before {
            content: "";
            position: absolute;
            top: 24px;
            left: 0;
            background-color: $accent-color;
            width: 1.125rem;
            height: 2px;
          }
        }
      }

      address {
        .address-title{
          font-size: 1.125rem;
          line-height: 1.33;
          position: relative;
          color: #000000;
          padding-bottom: 0.8125rem;
          margin: 0;
          &:after{
            content: "";
            position: absolute;
            background-color: $accent-color;
            width: 1.875rem;
            height: 2px;
            bottom: 0;
            left: 0;
          }
        }
        .list-group{
          .list-group-item {
            color: #000000;
            line-height: 1.33;
            align-items: flex-start;
            padding: 0.5rem 0.5rem 0.5rem 2.1rem;
            :first-child {
              position: absolute;
              left: 0;
              opacity: 0.6;
              top: 0.65rem;
              margin-right: 1rem;
            }
          }
        }
      }

      .aside-agency-card {
        display: flex;
        margin-top: 1.25rem;
        align-items: center;
        color: #000000;
        text-decoration: none;
        h4 {
          margin-left: 2.5rem;
          line-height: 1.33;
        }
      }
    }

  }
}

.modal-backdrop.show {
  opacity: .9;
}

.modal-body-register{
  margin-top:50px;
}

.modal-body-register label{
  display: inline;
}

.modal-footer-register button:hover{
  text-decoration: underline;
}
#app {
  position: relative;
}

@media (max-width: 599px) {
  .h1 {
    font-size: 2rem;
  }
}

  @media (max-width: 768px) {
    .post-section {
      padding-bottom: 0;
    }
}
