
#front-app {
  height: 100vh;
  .left-col {
    background-color: #0c2c47;

    .box {
      padding: 2.5rem 3.75rem;

      p {
        color: $white;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.33;
        padding-top: 1rem;
        margin: 0;

        &.bottom-border {
          padding: 1.125rem 0 1.25rem 0;
          position: relative;

          &:after {
            content: "";
            width: 5rem;
            height: 2px;
            background-color: $white;
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
          }
        }
      }

    }
  }

  .right-col {
    .box {
      width: 22.5rem;
      padding-top: 8.625rem;

      .h1 {
        font-size: 1.5rem;
        line-height: 1.25;
        color: $second-accent-color;
        position: relative;
        padding-bottom: 1.875rem;

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background-color: $second-accent-color;
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
        }

      }

      .box-body {
        p {
          margin-top: 1.4375rem;
          font-size: 0.875rem;
        }

        form {
          margin-top: 3.4375rem;

          .form-footer {
            margin-top: 3.75rem;

            .btn {
              font-weight: normal;
              padding: 0 0 1rem 0;
              width: 50%;
              color: #666666;

              &.active, &:hover {
                color: $second-accent-color !important;
              }

              &.active:after, &:hover:after {
                background: $second-accent-color !important;
                width: 100% !important;
              }

              &:after {
                background: $black;
                width: 2rem;
              }
            }
          }

          .form-group.is-focused {

            input {
              background-image: linear-gradient(to top, $blue 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, rgba(0, 0, 0, 0.26) 1px, rgba(0, 0, 0, 0) 1px);
            }

          }
        }
      }
    }
  }
}


.forgot-pass-page {
  #front-app {
    height: 100vh;
    .right-col {
      .box {
        .box-body {
          form {
            .form-footer {
              margin-top: 11.8125rem;
            }
          }
        }
      }
    }
  }
}

.login-page {
  #front-app {
    height: 100vh;
    .right-col {
      .box {
        .box-body {
          form {
            .form-group {
              a {
                color: #999999;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  }
}


