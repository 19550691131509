/*
 * Header
 *
 * Separated section of content at the top of all pages, save the homepage.
 */

header {
  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //z-index: 3;
  padding-bottom: 15px;
  border-bottom: 1px solid #EEEEEE;

  @media (max-width: 990px) {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .1);
  }

  ul {
    li {
      a {
        color: #333333;
        font-size: 16px !important;
        font-weight: 300 !important;
        text-transform: none !important;
        padding: 12px 20px !important;
        border-bottom: 3px solid transparent;
      }

      a:hover {
        border-bottom: 3px solid #FF0000;
      }
    }
  }

  .header-logo {
    margin-top: 15px;
  }

  .display-flex {
    display: flex;
  }

  #navbarSupportedContent {
    padding-top: 30px;
  }

  .dropdown-menu {
    padding: 0 !important;
  }

  a.dropdown-item {
    background-color: #FFFFFF;
    border-bottom: none;
  }

  a:hover.dropdown-item {
    background-color: #55595c;
    color: #FFFFFF;
    border-bottom: none;
  }

  .navbar {
    box-shadow: none;
    @media (max-width: 991.98px) {
      &.bg-transparent {
        background: rgba(255, 255, 255, 1) !important;
      }
    }
  }

  .navbar-toggler {
    position: relative;
    width: 40px;
    height: 40px;
    // background: $accent-color;

    & > span {
      position: absolute;
      width: 30px;
      height: 2px;
      background-color: $accent-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform .3s;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        background-color: $accent-color;
        transform-origin: center;
        transition: transform .3s, opacity .3s;
      }

      &:before {
        top: -8px;
      }

      &:after {
        bottom: -8px;
      }
    }

    &:not(.collapsed) {
      & >span {
        transform: translate(-50%, -50%) rotate(45deg);
        &:before {
          opacity: 0;
        }
        &:after {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .navbar-brand {
    padding-bottom: 0;

    @media (max-width: 990px) {
      width: 50px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  #navbarsMain {
    align-self: flex-start;
    padding: 0.9rem;

    @media (max-width: 991px) {
      padding: 0;
    }

    .navbar-nav {
      &:first-child {
        margin-left: 5.5rem;
        @media (max-width: 991.98px) {
          margin-left: 0;
          padding-top: .9rem;
        }
      }

      .nav-link {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1.1rem;
        margin-right: 1.9rem;
        margin-left: 0.6rem;
        position: relative;
        transition: color .2s;
        color: #333333;
        font-size: 16px !important;
        font-weight: 300 !important;
        text-transform: none !important;

        &:not(.dropdown-toggle) {
          &.active, &:hover {
            color: $accent-color;
          }

          &.active:after, &:hover:after {
            background: $accent-color;
          }

          &:after {
            content: "";
            background: transparent;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 1.5rem;
            transition: background-color .2s ease-in-out;
          }

          &.nav-link-sign-in {
            margin-right: 0;
            margin-left: 0;
            padding-left: 0.7rem;
            padding-right: 0.7rem;

            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
