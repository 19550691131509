/*
 * Footer
 *
 * Separated section of content at the bottom of all pages, save the homepage.
 */

.footer {
  color: $body-color;

  .navbar-copy-right {
    font-size: 14px;
    color: #a29f9f;
  }

  .display-flex {
    display: flex;
  }

  .copyright {
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    margin-top: 10px;
  }

  ul {
    li {
      a {
        color: #333333;
        font-size: 16px;
        font-weight: 300;
        text-transform: none;
        padding-bottom: 12px;
        border-bottom: 3px solid transparent;
      }

      a:hover {
        border-bottom: 3px solid #FF0000;
      }
    }
  }

  .footer-logo {
    height: 100px;
    padding: 20px;
    margin: 15px;
  }

  @media (min-width: 992px) {

  }
  @media (min-width: 768px) {
    .navbar-expand-lg {
      .navbar-collapse {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
      }

      .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;


        .nav-link {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 1.1rem;
          margin-right: 1.9rem;
          margin-left: 0.6rem;
          color: $body-color;
          position: relative;
          font-size: 0.875rem;
          text-transform: initial;
          transition: color .2s;

          &.active, &:hover {
            color: $accent-color;
          }


          &.nav-link-sign-in {
            margin-right: 0;
            margin-left: 0;
            padding-left: 0.7rem;
            padding-right: 0.7rem;

            &.active:after, &:hover:after {
              background: $accent-color;
              transition: all .2s ease-in-out;
            }

            &:after {
              width: 100%;
              content: "";
              background: transparent;
              position: absolute;
              bottom: 0;
              left: 0;
              height: 2px;
            }
          }
        }
      }
    }
  }
}
