/**{{ $agency->title }}
Agency inside
 */

.page-service-inside {
  #top-section {
    @include top-section('/images/frontend/images/service-inside.jpg');

    .box {

      p {
        font-size: 1.125rem;
        color: #666666;
        margin-top: 1.875rem;
      }
    }
  }

  main {
    padding-top: 8.375rem;
    background-color: #f7f7f7;
  }

}
