/*
 * Home Page
 */


/*
*  available-agencies and create account sections
*  are in  _global.scss because this section is repeated in another pages
*/


.page-home {
  #top-section {
    position: relative;
    padding-top: 20px;
    @media (max-width: 1200px) {
      height: auto;
    }

    h1 {
      color: #000000;
      line-height: 1.4em;

      .bold-red {
        color: #FF0000;
        font-weight: 400;
      }
    }

    p {
      color: #000000;
      font-size: 17px;
      font-weight: 300;
      line-height: 34px;
      padding-top: 20px;
    }

    .intro-content {
      .col-8 {
        max-width: 44.85rem;
        padding: 0;
      }

      .leaf-icon {
        width: 38px;
        height: 41px;
        display: inline-block;
        background: {
          size: cover;
          position: center center;
          image: url("../../../../public/images/frontend/bitmap.png");
        }
      }

      p {
        a {
          color: $body-color;
          text-decoration: underline;
        }
      }

      .btn {
        font-weight: normal;
        padding: 0 0 1rem 0;
        margin: 1.6rem 1rem;
        min-width: 10rem;
      }

      @media (max-width: 599px) {
        .btn {
          min-width: 6rem;
          margin: 1rem .5rem;

          &:after {
            width: 100%!important;
          }
        }
      }
    }
  }

  .divider {
    border-bottom: 2px solid #000000;
    margin: 40px 0 60px 0;
  }

  #page-description {
    .container-fluid {
      @media (min-width: 1200px) {
        max-width: 1290px;
      }
    }

    .half {
      margin-top: -14.375rem;
      @media (max-width: 1200px) {
        margin-top: auto;
      }

      .half-bg {
        position: absolute;
        content: "";
        top: 50%;
        width: 100%;
        height: 50%;
      }

      .box {
        background: {
          size: cover;
          position: center center;
          image: url("../../../../public/images/frontend/group-8.png");
        }

        .text-box {
          padding: 3.4rem 0 3.5rem 0;

          h2 {
            position: relative;
            padding-bottom: 1.5rem;

            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 2px;
              width: 2.5rem;
              background: $accent-color;
            }
          }

          p {
            padding: 1.2rem 6.2rem 0.5rem 0;
            font-size: 1.375rem;
            line-height: normal;
          }

          .btn {
            font-weight: normal;
            margin: 0.5rem 0;
            padding: 0.38rem 0 1rem 0;
            font-size: 1rem;
            width: 170px;
          }

          @media (max-width: 992px) {
            padding: 1.4rem 1rem 5.5rem 1rem;
            p {
              padding: 1.2rem 0 3rem 0;
            }
          }

          @media (max-width:599px) {
              padding-bottom: 2rem;

              p {
                padding-bottom: 1.2rem;
              }
          }
        }
      }
    }
  }

  #what-is-lasi {
    h2 {
      color: #FF0000;
      font-size: 32px;
      font-weight: 700;
      padding-bottom: 60px;
    }

    p {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
    }
  }

  #available-agencies {

    .bmd-form-group {
      padding-top: 0;
      margin-top: -20px;
    }

    .custom-control-label {
      padding-top: 5px;
    }

    .dropdown-menu.filter-options {
      max-height: 400px;
      overflow-y: scroll;
      border: 2px solid #FF0000;
    }

    .dropdown-menu {
      padding: 0;
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;

    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    h2 {
      font-size: 24px;
      font-weight: 300;
      color: #000000;
      padding: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      color: #000000;
      text-transform: uppercase;
    }

    ul {
      li {
        padding: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #000000;

        i {
          font-size: 25px;
          color: #FF0000;
          line-height: 25px;
          margin-right: 15px;
        }
      }
    }

    .agency-header {
      padding-left: 0;
      font-weight: 500;
      font-size: 19px;
      line-height: 29px;
      color: #000000;
    }

    .margin-top-auto {
      margin-top: auto;
    }

    .btn-outline-primary {
      color: #000000;
      font-size: 17px;
      font-weight: 700;
      border: 2px solid #FF0000;
      border-radius: 3px;
      text-transform: none;
      margin-bottom: 20px
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    .slick-slide > div {
      margin-right: 15px;
      margin-left: 15px;
    }

    .slider-img {
      display: flex!important;
      justify-content: center;
      align-items: center;
    }

    .card-deck {
      margin: 15px 0;
    }

    .card-body {
      padding-bottom: 0px;
    }

    .agency-card {
      border-radius: 6px;
      border: 1px solid #000000;
      padding: 15px;
      margin: 12px 0;
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);

    }

    h5.filter-header {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
      padding-bottom: 1.25REM;
      padding-right: 20px;
    }

    .bmd-label-static {
      color: #000000;
      font-weight: 500;
      margin-left: 20px;
      margin-bottom: 25px;
      position: relative;
    }

    .dropdown-toggle {
      white-space: nowrap;
      border-radius: 3px;
      border: 2px solid #FF0000;
      padding: 10px;
      color: #333333;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      margin: 10px;
    }

    #coalition-agencies {
      max-width: 1190px;
      width: 100%;
    }

    .selected-filter-options {
      span {
        background-color: red;
        border: 1px solid red;
        border-radius: 15px;
        margin-left: 10px;
        margin-bottom: 5px;
        color: #ffffff;
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;


        i {
          font-style: normal;
          width: 16px;
          display: inline-block;
          text-align: center;
          height: 23px;
          line-height: 24px;
          cursor: pointer;
        }
      }

    }

    @media(max-width: 768px) {
      .bmd-label-static {
        margin: 0;
      }

      h5.filter-header {
        width: inherit;
        text-align: center;
        padding-bottom: 10px;
      }

      .bmd-form-group {
        padding-top: 0;
        margin-bottom: 10px;
        margin-top: -20px;
      }

      .dropdown-toggle {
        padding: 10px;
        margin: 2px;
        font-size: 14px;
      }

      .selected-filter-options {
        margin-top: 10px;
      }
    }
  }

  #our-services {
    .fast-forward {
      object-fit: contain;
      opacity: 0.05;
    }

    .home-services-box {
      .h5 {
        position: relative;
        padding-bottom: 1.43rem;
        margin-bottom: 1rem;
        transition: color .2s;

        &:after {
          background: $black;
          width: 3rem;
          transition: width .2s, background-color .2s;
        }
      }

    }

  }

  #create-account, #contact-us {
    h2 {
      color: #FF0000;
      font-size: 32px;
      font-weight: 700;
      padding-bottom: 50px;
    }

    h3 {
      color: #000000;
      font-size: 23px;
      font-weight: 300;
      margin-bottom: 20px;
    }

    p {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
    }

    .form-control {
      border: 1px solid #818a91;
      border-radius: 3px;
      font-size: 15px;
      margin-bottom: 10px;
      color: #333333;
      padding: 15px;
      background-image: none;
    }

    .bmd-form-group {
      padding-top: 0;
    }

    .btn-outline-primary {
      color: #000000;
      font-size: 17px;
      font-weight: 700;
      border: 2px solid #FF0000;
      border-radius: 3px;
      text-transform: none;
      margin-top: 40px;
      margin-bottom: 10px
    }

    .margin-0 {
      margin: 0;
    }

    @media (max-width: 768px) {
      .btn-outline-primary {
        margin: 5px 0 40px 0;
      }
    }
  }
}

@media (min-width: 992px){
  #what-is-lasi.pb-lg-10, .py-lg-10 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 768px){
  #available-agencies.pt-md-8, .py-md-8 {
    padding-top: 30px !important;
  }
}



