.btn-facebook {
  @include button-variant(#365899, #365899);
}

.btn-twitter {
  @include button-variant(#1DA1F2, #1DA1F2);
}

.btn-google {
  @include button-variant(#DB4437, #DB4437);
}

.btn-linkedin {
  @include button-variant(#0077B5, #0077B5);
}

.btn-github {
  @include button-variant(#24292E, #24292E);
}

.btn-bitbucket {
  @include button-variant(#253858, #253858);
}

.btn-bordered {
  font-weight: normal;
  font-size: 0.875rem;
  color: #999999;
  background-color: #ffffff;
  border-radius: 12px;
  border: solid 1px #999999;
  padding: 2px 8px;
  text-transform: initial;

  &:active, &:hover, &:focus, &.active:hover, &.active:focus, &:active:hover, &:active:focus {
    color: #ffffff;
    border-color: $accent-color;
    background-color: $accent-color;
  }
}

.bottom-outline {
  font-weight: normal;
  box-shadow: none !important;
  text-transform: initial;
  position: relative;
  background: none !important;
  border: none !important;
  padding: 0.38rem 0 1rem 0;
  font-size: 1.125rem;

  &.active, &:hover {
    color: $accent-color !important;
  }

  &.active:after, &:hover:after {
    background: $accent-color !important;
    transition: all .2s ease-in-out;
    opacity: 1;
    width: 160px !important;
  }

  &:after {
    content: "";
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    opacity: 0.1;
  }
}

.btn-outline-primary {

  &.active, &:hover {
    border-style: solid !important;
    border-width: 1px !important;
  }
}
