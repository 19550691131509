// Slick
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
$slick-arrow-color: black;
$slick-dot-color: black;


// intlTelInput
$flagsImagePath: "../../build/img/" !default;


// Font

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');


$font-family-jost: Roboto, sans-serif !default;
$accent-color: #fe0000;
$second-accent-color: #0089ff;
$gray-section: #f7f7f7;

// Bootstrap overrides
$font-family-base: $font-family-jost;
//$line-height-base: normal;
$font-weight-base: normal;
$body-bg: #ffffff;
$border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$body-color: #333333;
//$line-height-base: 1.25;
$theme-colors: (
        "primary": $accent-color,
        "gray-custom": $gray-section
);

$headings-font-weight: 300;
$headings-line-height: 1;
$h1-font-size: 2.5rem;
$font-size-base: 1rem;
$font-weight-base: 400;
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3),
                        6: ($spacer * 4),
                        7: ($spacer * 5),
                        8: ($spacer * 6.25),
                        9: ($spacer * 7.5),
                        10: ($spacer * 9.375)
                ),
                $spacers
);
