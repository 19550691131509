/*
 * About us Page
 */

.page-provider{
  #top-section{
    .box{
      min-height: 95%;
    }
  }
}

.page-contact,.page-provider {
  #top-section {
    @include top-section('/images/frontend/images/page-contact.jpg', 100%);

    .box {
      padding: 3.75rem 4.0625rem;
      margin-bottom: -7%;

      @media (max-width: 600px) {
        padding: 1rem!important;
      }

      form,
      #send-email-form-frontend {
        .btn {
          padding: 0 0 1rem 0;
          position: relative;
          margin-top: 3.5rem;
          font-weight: normal;
          min-width: 6.25rem;

          &.active:after, &:hover:after {
            width: 100% !important;
          }

          &:after {
            background: $black;
            width: 2rem;
          }
        }
        span {
          display: block;
          margin: 0;
          height: 20px;
          line-height: 20px;
        }
        textarea,
        input {
          &:focus {
            box-shadow: none;
          }
        }
        textarea {
          min-height: 50px;
          max-height: 250px;
          resize: vertical;
        }
      }
    }
  }


  main {
    height: 13.5rem;
    background: #f7f7f7;
  }
}
