/*
 * About us Page
 */


.page-about {
  #top-section{
    @include top-section('/images/frontend/images/page-about.jpg');
    display: flex;
    align-items: flex-end;
    height: 100%;
    min-height: 45rem;
    padding-top: 120px;

    &:before {
      top: 0;
    }

    @media (max-width: 599px) {
      .top-section-list {
        font-size: 14px;
        padding: 0;
      }
    }

    @media (max-width: 450px) {
      .top-section-block {
        .h5 {
          font-size: 1rem!important;
        }
      }
    }
  }

  #available-agencies {
    background: $gray-section !important;

    .slick-track {
      display: flex;
      align-items: center;
    }

    .slick-slide > div {
      margin-right: 15px;
      margin-left: 15px;
    }

    .slider-img {
      display: flex!important;
      justify-content: center;
      align-items: center;
    }
  }

  #questions {
    p {
      font-size: 1.375rem;
    }

    img {
      opacity: 0.05;
    }
  }
}
